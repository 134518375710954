import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import {appDatabasePrimaryFunctions, base} from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'html-verify-react-rte';
import {PhotoshopPicker} from "react-color";

class SetUpTickets extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            editingTicket: false,
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            emailImage: null,
            rewardName: "",
            rewardDisplayName: "",
            rewardsList: [],
            rewardLink: "",
            rewardLinkButtonText: "",
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            rewardDescription: RichTextEditor.createEmptyValue(),
            editTicketId: "",
            editTicketEmailImage: '',
            editTicketPictureUnderScratcher: '',
            revealImagePreview: {},
            emailImagePreview: {},
            loading:true,
            files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.rewardsListRef = base.syncState(`rewardsList`, {
        context: this,
        state: 'rewardsList',
        asArray: true
      });
      this.setState({loading:false})
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              const nameToUpdate = myArgument + "Preview"
              const fileToUpdate = files[0]
              this.setState({
                  emailImage: res.imageUrl,
                  [nameToUpdate]: ({
                      fileToUpdate,
                      preview: URL.createObjectURL(fileToUpdate)
                  })
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    onChangeDescription = (rewardDescription) => {
        this.setState({
            rewardDescription,
        })
    }

    openColorPicker(colorToChange){
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        const colorToChange = this.state.colorToUpdate;
        this.setState({
            [colorToChange]: this.state.colorToShow,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.revealImagePreview.preview);
      URL.revokeObjectURL(this.state.emailImagePreview.preview);
      base.removeBinding(this.rewardsListRef);
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.loading){
            return
        }
        const rewardName = this.state.rewardName || "";
        const rewardDisplayName = this.state.rewardDisplayName || "";
        const rewardLink = this.state.rewardLink || "";
        const rewardLinkButtonText = this.state.rewardLinkButtonText || "";
        const rewardLinkButtonTextColor = this.state.rewardLinkButtonTextColor || "";
        const rewardLinkButtonColor = this.state.rewardLinkButtonColor || "";
        const showButton = this.state.showButton || false;
        let rewardDescription = this.state.rewardDescription.toString('html');
        if(rewardDescription === "<p><br></p>"){
            rewardDescription = "";
        }
        const emailImage = this.state.emailImage || this.state.editTicketEmailImage || "";
        const vm = this;
        if(!rewardName){
        swal({
            title: 'Not all fields filled out!',
            text: 'You need to add a reward name',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
            return;
        }
        const rewardToSave = {}
        rewardToSave['rewardName'] = rewardName;
        rewardToSave['rewardDisplayName'] = rewardDisplayName;
        rewardToSave['emailImage'] = emailImage;
        rewardToSave['rewardLink'] = rewardLink;
        rewardToSave['rewardLinkButtonText'] = rewardLinkButtonText;
        rewardToSave['rewardLinkButtonTextColor'] = rewardLinkButtonTextColor;
        rewardToSave['rewardLinkButtonColor'] = rewardLinkButtonColor;
        rewardToSave['description'] = rewardDescription;
        rewardToSave['showButton'] = showButton;
        this.setState({loading:true})
        let rewardId = (vm.state.rewardToEdit && vm.state.rewardToEdit.key) || appDatabasePrimaryFunctions.ref().push().key;
        rewardToSave['id'] = rewardId;
        base.post(`rewardsList/${rewardId}`, {
          data: rewardToSave,
          then(err){
            if(!err){
                swal({
                    title: vm.state.rewardToEdit?'Ticket Edited!':'Ticket Created!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
                vm.setState({
                    rewardDescription: RichTextEditor.createEmptyValue(),
                    fileEmailImage: null,
                    rewardName: "",
                    emailImage: null,
                    rewardLink: "",
                    rewardLinkButtonText: "",
                    rewardLinkButtonTextColor: "",
                    rewardLinkButtonColor: "",
                    rewardToEdit: null,
                    revealImagePreview: {},
                    emailImagePreview: {},
                    editingTicket: false,
                    modal: false,
                    rewardDisplayName: "",
                    loading:false,
                    showButton: false
                })
            } else {
              vm.setState({loading:false})
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              console.log(err)
            }
          }
        })
    }

    editTicket(event){
        event.preventDefault();
        const array = this.state.rewardsList;
        const reward = array[event.target.value];
        this.setState({
            modal: true,
            rewardName: reward.rewardName,
            rewardLink: reward.rewardLink,
            rewardDescription: RichTextEditor.createValueFromString(reward.description, 'html'),
            rewardLinkButtonText: reward.rewardLinkButtonText,
            rewardLinkButtonColor: reward.rewardLinkButtonColor,
            rewardLinkButtonTextColor: reward.rewardLinkButtonTextColor,
            editTicketEmailImage:reward.emailImage,
            rewardToEdit: reward,
            rewardDisplayName: reward.rewardDisplayName,
            showButton: reward.showButton,
            editingTicket: true
        });
    }

    deleteTicket(e){
      e.preventDefault();
      var array = this.state.rewardsList;
      var index = array[e.target.value];
      swal({
            title: 'Delete Ticket?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this ticket in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result) {
            base.remove('rewardsList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        rewardName: "",
        rewardLink: "",
        rewardDescription: RichTextEditor.createEmptyValue(),
        rewardLinkButtonText: "",
        rewardLinkButtonColor: "",
        rewardLinkButtonTextColor: "",
        editTicketEmailImage:null,
        rewardToEdit: null,
        rewardDisplayName: "",
        editingTicket: false,
        showButton: false
      });
    }

    render() {
        const winningTicketList = this.state.rewardsList;
        const emailImagePreview = this.state.emailImagePreview;
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Prizes</p>
                    <p className="admin-subheader-text">These are prizes fans will receive when they win</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Prize</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        winningTicketList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.rewardName}</p>
                                <p>
                                  <img width="80%" height="auto" src={item.emailImage} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add Prize</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group" >
                          <label htmlFor="rewardName">Prize Name</label>
                          <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                        </div>
                        <div className="form-group" >
                            <label htmlFor="rewardDisplayName">Prize Display Name</label>
                            <input id="rewardDisplayName" name="rewardDisplayName" type="text" className="form-control" value={this.state.rewardDisplayName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="rewardLink">Prize Link (Optional)</label>
                          <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com"/>
                        </div>
                        {this.state.rewardLink &&
                          <div className="form-group">
                              <label htmlFor="showButton">Show Button</label>
                              <br/>
                              <input type="checkbox" checked={this.state.isRedeemable || this.state.showButton} id="showButton" name="showButton" onChange={this.handleChange} disabled={this.state.isRedeemable && true}/>
                          </div>
                        }
                        {this.state.showButton &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="rewardLinkButtonText">Prize Link Button Text (Optional)</label>
                                    <input id="rewardLinkButtonText" name="rewardLinkButtonText" type="text" className="form-control" value={this.state.rewardLinkButtonText} onChange={this.handleChange} placeholder="Reward Link"/>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-inline">
                                            <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("rewardLinkButtonTextColor")}/>
                                            <div style={{backgroundColor: this.state.rewardLinkButtonTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                            <label htmlFor="rewardLinkButtonTextColor" style={{marginRight: 10}}>Button Text Color</label>
                                            <input id="rewardLinkButtonTextColor" name="rewardLinkButtonTextColor" type="text" className="form-control" value={this.state.rewardLinkButtonTextColor} onChange={this.handleChange} placeholder="#000"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-inline">
                                            <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("rewardLinkButtonColor")}/>
                                            <div style={{backgroundColor: this.state.rewardLinkButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                            <label htmlFor="rewardLinkButtonColor" style={{marginRight: 10}}>Button Color</label>
                                            <input id="rewardLinkButtonColor" name="rewardLinkButtonColor" type="text" className="form-control" value={this.state.rewardLinkButtonColor} onChange={this.handleChange} placeholder="#000"/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="form-group">
                            <label htmlFor="rewardDescription">Prize Description: </label>
                            <RichTextEditor toolbarConfig={toolbarConfig} id="rewardDescription" name="rewardDescription" type="text" value={this.state.rewardDescription} onChange={this.onChangeDescription} placeholder="My Sweet Prize Description"/>
                        </div>
                        <div className="form-group" align="center">
                          <label htmlFor="rewardEmailImage" style={{width:'100%'}}>
                              Prize Email Image
                              <span style={{cursor:"pointer", display: emailImagePreview.preview || this.state.editTicketEmailImage?"":"none"}} onClick={()=>this.setState({editTicketEmailImage: null, fileUploaded:false, emailImagePreview: {}, emailImage:null})}>
                                  ❌
                              </span>
                          </label>
                          <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                          <Dropzone
                            className="dropzone dz-clickable"
                            accept="image/*"
                            onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'emailImage')}}
                            multiple={false}
                            maxSize={2200000}>
                            <div className="dz-message needsclick">
                              <span className="fa fa-cloud-upload text-muted" style={{display: emailImagePreview.preview ? 'none' : ''}}/>
                              <h3 style={{display: emailImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                              <img
                                style={{display: emailImagePreview.preview ? '' : 'none'}}
                                src={emailImagePreview.preview}
                                width="100px"
                                height="auto"
                                alt="Drop your image here"
                              />
                            </div>
                          </Dropzone>
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpTickets
