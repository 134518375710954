import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import {Modal} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base} from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import AuthService from '../utils/AuthService';
import DatePicker from '../utils/DatePicker';
import {
    cleanCSVText,
    convertTimeStampToHumanReadable,
    getSnapshotFromEndpoint,
    findFirstAndLastName,
    valueDoesExist,
    returnTimeStampInMilliseconds,
    detectHowManyCodesEntered
} from '../utils/HelpfulFunction';
import TimePicker from 'react-time-picker';
import {sortPlayersForAdmin} from 'myshared';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
            ticketList: [],
            questionsList: [],
            tenantVariables: {},
            id_key: "",
            userAnswers:[],
            currentGame: null,
            modal: false,
            gameName: "",
            loading: true,
            wizardLocation: 'first',
            showStats: true,
            predictionsAnsweredModal: false,
            endTime: new Date(),
            startTime: new Date(),
            itemsPerPage: 25,
            itemsToShow: 25,
            losingRewardsAdded: [],
            rewardsAdded: [],
            rewardToAdd: '',
            rewardAmount: 0,
            codes: false,
            codesArray: '',
            qrCodes: false,
            showWinning: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.togglePredictionsAnsweredModal = this.togglePredictionsAnsweredModal.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.saveReward = this.saveReward.bind(this);
    }

    componentDidMount() {
        const vm = this;
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.idKeyRef = base.fetch(`id_key`, {
            context: this,
            then(data){
              this.setState({
                id_key: data
              })
            }
        });
        this.rewardsListRef = base.syncState(`rewardsList`, {
            context: this,
            state: 'ticketList',
            asArray: true
        });
        this.questionsListRef = base.bindToState('questionsList',{
            context: this,
            state: 'questionsList',
            asArray: true
        })
        this.userAnswersRef = appDatabasePrimaryFunctions.ref("userAnswers").on('value', function(snapshot){
            vm.setState({
                userAnswerCount: snapshot.numChildren()
            })
        })
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
              if(!err){
                this.setGameToWatch(this.state.currentGame.id);
              }
              this.setState({loading:false})
            }
        });
    }

    downloadUsers(){
      this.setState({loading: true});
      const vm = this;
      let csv = 'Email,First Name,Last Name,Zip Code,Birthday,How You Heard?,Opt-In,Opt-In 2,Answer,Reward,Code,Answer Time\n';
      Promise.all([getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
          let users = {};
          if(snapshots[0].exists() && snapshots[0].val()){
              users = snapshots[0].val();
          }
          let answerList = await sortPlayersForAdmin(0, null, appDatabasePrimaryFunctions, vm.state.currentGame);
          answerList.forEach(function(row) {
              let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
              csv += (users[row.uid].email || "");
              csv+= ",";
              csv+= (cleanCSVText(firstName) || "");
              csv+= ",";
              csv+= (cleanCSVText(secondName) || "");
              csv+= ",";
              csv+= (cleanCSVText(users[row.uid].zipCode) || "");
              csv+= ",";
              csv+= (users[row.uid].birthday || "");
              csv+= ",";
              csv+= (cleanCSVText(users[row.uid].howYouHeard) || "");
              csv+= ",";
              csv+= (users[row.uid].optIn || "");
              csv+= ",";
              csv+= (users[row.uid].optInTwo || "");
              csv+= ",";
              csv+= cleanCSVText(valueDoesExist(row.answer)?row.answer:"");
              csv+= ",";
              csv+= (cleanCSVText(row.rewardSent) || "NONE");
              csv+= ",";
              csv+= (cleanCSVText(row.code) || "-");
              csv+= ",";
              csv += convertTimeStampToHumanReadable(parseInt(row.timeStamp));
              csv += "\n";
          }, this);
          let hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          hiddenElement.target = '_blank';
          let date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv"
          hiddenElement.download = "users_" + date_got;
          hiddenElement.click();
          vm.setState({loading: false})
      })
    }

    setGameToWatch(id){
      if(this.userGameHistoryRef){
          appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      if(id){
          const vm = this;
          this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
              vm.setState({
                  userGameHistory: snapshot.numChildren()
              })
          })
      }
    }

    componentWillUnmount() {
        if(this.userGameHistoryRef){
            appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        appDatabasePrimaryFunctions.ref('userAnswers').off('value', this.userAnswersRef);
        base.removeBinding(this.tenantVariablesRef);
        base.removeBinding(this.rewardsListRef);
        base.removeBinding(this.questionsListRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.idKeyRef);
    }

    findItem(itemId, list){
      for(let itemIndex in list){
        let item = list[itemIndex]
        if(item.key === itemId){
          return Object.assign({}, item);
        }
      }
    }

    async createGame(){
        let createGameObject = {};
        let gameId = appDatabasePrimaryFunctions.ref().push().key;
        let questionAdded = this.state.question_select;
        let rewardsAdded = this.state.rewardsAdded || [];
        let losingRewardsAdded = this.state.losingRewardsAdded || [];
        let sendLosingEmails = this.state.sendLosingEmails || false;
        const noPrizes = this.state.noPrizes || false;
        const gameStartTime = this.state.schedule_game_time;
        const gameEndTime = this.state.schedule_end_game_time;
        const scheduledGame = this.state.schedule_game;
        const futureGames = this.state.futureGamesList || [];
        let shouldReplaceCurrentGame  = true;
        if(noPrizes){
            rewardsAdded = [];
            losingRewardsAdded = [];
        }
        let readyToUploadPrizes = null;
        let hasRewardWithAutoRedemptions = false;
        if(rewardsAdded.length !== 0){
            let totalAmount = 0;
            readyToUploadPrizes = {};
            for(const rewardIndex in rewardsAdded){
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key] = rewardsAdded[rewardIndex].reward;
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['amount'] = parseInt(rewardsAdded[rewardIndex].amount);
                totalAmount+=parseInt(rewardsAdded[rewardIndex].amount);
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codes'] = rewardsAdded[rewardIndex].codes;
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['qrCodes'] = rewardsAdded[rewardIndex].qrCodes;
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codesArray'] = rewardsAdded[rewardIndex].codesArray || [];
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['pinsArray'] = rewardsAdded[rewardIndex].pinsArray || [];
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['used'] = 0;
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['isRedeemable'] = rewardsAdded[rewardIndex].reward.isRedeemable || false;
                if(readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['isRedeemable']){
                    hasRewardWithAutoRedemptions = true;
                }
            }
            readyToUploadPrizes["totalRewards"] = totalAmount;
            readyToUploadPrizes["totalRewardsUsed"] = 0;
            readyToUploadPrizes["randomPrizeOrder"] = this.state.randomOrder || false;
        }
        let readyToUploadLosingPrizes = null;
        if(losingRewardsAdded.length !== 0){
            let totalAmount = 0;
            readyToUploadLosingPrizes = {};
            for(const losingRewardIndex in losingRewardsAdded){
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key] = losingRewardsAdded[losingRewardIndex].reward;
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['amount'] = parseInt(losingRewardsAdded[losingRewardIndex].amount);
                totalAmount+=parseInt(losingRewardsAdded[losingRewardIndex].amount);
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['codes'] = losingRewardsAdded[losingRewardIndex].codes;
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['qrCodes'] = losingRewardsAdded[losingRewardIndex].qrCodes;
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['codesArray'] = losingRewardsAdded[losingRewardIndex].codesArray || [];
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['pinsArray'] = losingRewardsAdded[losingRewardIndex].pinsArray || [];
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['used'] = 0;
                readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['isRedeemable'] = losingRewardsAdded[losingRewardIndex].reward.isRedeemable || false;
                if(readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['isRedeemable']){
                    hasRewardWithAutoRedemptions = true;
                }
            }
            readyToUploadLosingPrizes["totalRewards"] = totalAmount;
            readyToUploadLosingPrizes["totalRewardsUsed"] = 0;
            readyToUploadLosingPrizes["randomPrizeOrder"] = this.state.losingRandomOrder || false;
            sendLosingEmails = true;
        }
        if(losingRewardsAdded.length === 0 && rewardsAdded.length === 0 && !noPrizes){
            swal({
                title: "Hold On!",
                text: 'No prizes have been added and no prizes box is not checked!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        createGameObject.rewards = readyToUploadPrizes;
        createGameObject.losingRewards = readyToUploadLosingPrizes;
        createGameObject.hasRewardWithAutoRedemptions = hasRewardWithAutoRedemptions;
        createGameObject.sendLosingEmails = sendLosingEmails || false;
        let gameName = this.state.gameName.trim();
        if(!gameName){
        swal({
            title: "Hold On!",
            text: 'The game needs a name!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return
        }
        if(scheduledGame){
            const currentTimeSeconds = Date.now();
            const inputtedDateSeconds = gameStartTime.getTime();
            const inputtedEndDateSeconds = gameEndTime.getTime();
            if(!inputtedDateSeconds || !inputtedEndDateSeconds){
                swal({
                    title: "Hold On!",
                    text: 'You have marked that you are scheduling but no game time entered!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            } else if(inputtedDateSeconds <= currentTimeSeconds) {
                swal({
                    title: "Hold On!",
                    text: 'You have marked that you are scheduling a game but it is before now!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            } else if(inputtedEndDateSeconds && inputtedEndDateSeconds <= inputtedDateSeconds){
                swal({
                    title: "Hold On!",
                    text: 'If you have an end time it must be in the future',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
            for(const fGameIndex in futureGames){
                const futureGame = futureGames[fGameIndex];
                if(inputtedDateSeconds === futureGame.activateGame.performAt){
                    swal({
                        title: "Hold On!",
                        text: 'You already have a scheduled game at that time!',
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return;
                }
            }
            createGameObject['activateGame'] = {};
            createGameObject['activateGame']['performAt'] = inputtedDateSeconds;
            createGameObject['activateGame']['endAt'] = inputtedEndDateSeconds;
            createGameObject['activateGame']['status'] = "scheduled";
        }
        if(!questionAdded){
            swal({
                title: "Hold On!",
                text: 'The game needs a prediction!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return
        }
        createGameObject.gameName = gameName;
        createGameObject.question = this.findItem(questionAdded, this.state.questionsList);
        if(createGameObject.question && createGameObject.question.questionType !== "score"){
            createGameObject.question.teamOneImage = null;
            createGameObject.question.teamTwoImage = null;
        }
        if(createGameObject.question && !createGameObject.question.answerImage){
            createGameObject.question.answerImage = false;
        }
        createGameObject.firstStart = false;
        createGameObject.correctAnswer = false;
        createGameObject.gameType = "prediction_slider";
        createGameObject.timeStamp = Date.now()
        createGameObject.id = gameId;
        createGameObject.active = false;
        let currentGame = this.state.currentGame || {};
        if(currentGame.gameName && scheduledGame){
            shouldReplaceCurrentGame = false;
        }
        const vm = this;
        this.setState({
            loading: true
        })
        if(shouldReplaceCurrentGame){
            const onSaveAndClearSuccess = await this.saveAndClearGame(createGameObject, createGameObject.id);
            if(!onSaveAndClearSuccess){
                this.setState({loading: false});
                swal({
                    title: "Hold On!",
                    text: 'Failed to clear out old game, please try again!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        } else if(createGameObject.id) {
            await base.post('futureGamesList/'+ createGameObject.id, {
                data: createGameObject,
                then(err){
                    if(!err){
                        vm.setState({
                            modal: false,
                            loading: false
                        })
                    }
                }
            })
            if(this.state.showGames){
                const futureGameList = await this.getFutureGames();
                this.setState({
                    futureGamesList: futureGameList
                })
            }
        }
        this.setState({
            modal: false,
            loading: false
        })
    }

    async saveAndClearGame(futureGame, futureGameId){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({futureGame: futureGame, futureGameKey: futureGameId})
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
            return !(!response || response.error);
        } catch (e) {
            console.log("ERROR: ", e);
            return false;
        }
    }

    async makeFutureGameCurrent(gameToSetCurrent){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({futureGame: gameToSetCurrent, futureGameKey: gameToSetCurrent.id})
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
            return false;
        }
        const responseJson = await Promise.resolve(response.json());
        if(responseJson.error){
            this.setState({loading: false});
            swal({
                title: "Oh No!",
                text: 'Something went wrong, please try again!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const futureGameList = await this.getFutureGames();
        // await this.getRedemptions(gameToSetCurrent.id);
        this.setState({
            futureGamesList: futureGameList,
            loading:false
        })
        swal({
            title: 'Game made current!',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    async removeGameFromFutureList(gameToRemoveId){
        const result = await swal({
            title: 'STOP!',
            text: 'Are you sure you want to do this?  This will delete this future game.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'YES I WANT TO DELETE THE GAME',
        });
        if(!result || !result.value) return;
        this.setState({loading: true})
        var futureGameList = this.state.futureGamesList;
        for(var gameIndex in futureGameList){
            var futureGame = futureGameList[gameIndex];
            if(futureGame.id === gameToRemoveId){
                futureGameList.splice(gameIndex, 1)
                if(gameToRemoveId){
                    await base.remove('futureGamesList/'+gameToRemoveId)
                }
            }
        }
        this.setState({
            futureGamesList: futureGameList,
            loading: false
        })
        swal({
            title: 'Future Game Deleted!',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    stopGame(){
      let currentGame = this.state.currentGame;
      currentGame.active = false;
      this.setState({
        currentGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
        let currentGame = this.state.currentGame;
        this.setState({loading:true})
        currentGame.timeStamp = Date.now()
        currentGame.firstStart = true;
        currentGame.active = true;
        this.setState({
            currentGame: currentGame,
            loading: false
        })
        swal({
            title: 'Game Started',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    endGame(){
        this.togglePredictionsAnsweredModal();
    }

    handleChange (evt) {
      let target = evt.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    handleTimeChange(event){
        this.setState({
            correctAnswer: event
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
      });
    }

    togglePredictionsAnsweredModal() {
        this.setState({
            predictionsAnsweredModal: !this.state.predictionsAnsweredModal,
        });
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then(async (result)=>{
          if(result.value){
            let currentGameState = await appDatabasePrimaryFunctions.ref('currentGame').once('value').then(function(snapshot){
                return snapshot.val() || {};
            });
            currentGameState.firstStart = false;
            currentGameState.answered = false;
            currentGameState.active = false;
            currentGameState.correctAnswer = false;
            let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGameState.id).equalTo(currentGameState.id).once('value').then(function(snapshot){
                return snapshot.val() || {};
            });
            for(let questionIndex in currentGameState.question.answers){
               currentGameState.question.answers[questionIndex].correct = false
            }
            for(let historyRef in history){
              let refInstance = history[historyRef];
              if(refInstance && refInstance[currentGameState.id]){
                  appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGameState.id}`).set(null);
              }
            }
            if(currentGameState.id){
                appDatabasePrimaryFunctions.ref(`currentGameFormFilled/${currentGameState.id}`).set(null);
            }
            appDatabasePrimaryFunctions.ref("emailsSent").set(null);
            appDatabasePrimaryFunctions.ref("prizeWon").set(null);
            appDatabasePrimaryFunctions.ref("userAnswers").set(null);
            this.setState({
                currentGame: currentGameState,
                currentUserAnswersState: []
            })
          }
        })
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    saveReward (evt) {
        this.setState({ rewardToAdd: evt.target.value });
    }

    async switchStatsPrizes(buttonClicked){
        if(buttonClicked === "showStats"){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats:true,
                showPrizes:false,
                showGames:false
            })
        }else if(buttonClicked === "showPrizes"){
            document.getElementById('showPrizes').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            this.setState({
                loading: true
            })
            const organizeUserAnswers = await sortPlayersForAdmin(0, '', appDatabasePrimaryFunctions, this.state.currentGame, process.env.REACT_APP_IS_MLB_TEAM === "true");
            this.setState({
                showStats:false,
                showPrizes:true,
                showGames:false,
                loading: false,
                currentUserAnswersState: organizeUserAnswers
            })
        } else if(buttonClicked === "showGames"){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            this.setState({
                loading: true
            })
            const futureGameList = await this.getFutureGames();
            this.setState({
                loading: false,
                showStats:false,
                showPrizes:false,
                showGames:true,
                futureGamesList: futureGameList
            })
        }
    }

    async markAsCorrect(){
        let answer = this.state.correctAnswer;
        const teamOneScore = this.state.teamOneScore;
        const teamTwoScore = this.state.teamTwoScore;
        const currentGame = this.state.currentGame || {};
        currentGame.answered = true;
        if(currentGame.question && currentGame.question.questionType === "time"){
            answer = answer.split(":")[1] + ":" + answer.split(":")[2]
            currentGame.correctAnswer = answer;
        } else if(currentGame.question && currentGame.question.questionType === "score"){
            answer = teamOneScore + " - " + teamTwoScore;
            currentGame.correctAnswer = answer;
        } else {
            currentGame.correctAnswer = answer;
        }
        const vm = this;
        this.setState({loading: true})
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendPrizesWithLosingPrize`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/sendPrizesWithLosingPrize`
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({currentGame: currentGame, answer: answer, fromAdmin: true})
        }).then(async res => {
            if(res) {
                const value = await Promise.resolve(res.json());
                vm.newHandleEmailResponse(value);
            } else {
                vm.setState({
                    loading:false
                })
            }
        }).catch((e)=>{
            console.log(e);
            vm.setState({loading:false})
        })
    }

    switchWinning(showBoolean) {
        if (showBoolean) {
            document.getElementById('showWinning').classList.add('active');
            document.getElementById('showLosing').classList.remove('active');
        } else {
            document.getElementById('showWinning').classList.remove('active');
            document.getElementById('showLosing').classList.add('active');
        }
        this.setState({
            showWinning: showBoolean
        })
    }

    newHandleEmailResponse(response){
        this.setState({
            loading: false
        })
        let popUpTitle = "Successfully Sent!";
        let error = false;
        if(!response || response.result === 'no_data'){
            popUpTitle = 'Something went wrong!  Check your internet connection and try again!';
            error = true;
        } else if(response.error) {
            popUpTitle = response.error;
            error = true;
        }

        if(error){
            swal({
                title: "Oh No!",
                text: popUpTitle,
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        if(response.result === "finished") {
            const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
            const noEmail = this.state.tenantVariables.doNotCollectEmail;
            if(isMlbApp || noEmail){
                popUpTitle = "Game Ended Successfully"
            }
            swal({
                title: popUpTitle,
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            })
            this.endGameAfterEmailsSent();
        }
    }

    async endGameAfterEmailsSent() {
        const currentGame = this.state.currentGame;

        // Update local state
        currentGame.videoState = 2;
        currentGame.sentEmail = true;

        this.setState({
            loading: false,
            currentGame: currentGame,
        }, async () => {
            // Update Firebase with the new game state
            const currentGameRef = appDatabasePrimaryFunctions.ref(`currentGame`);
            try {
                await currentGameRef.update({
                    videoState: currentGame.videoState,
                    sentEmail: currentGame.sentEmail
                });
                const organizeUserAnswers = await sortPlayersForAdmin(
                    null,
                    null,
                    appDatabasePrimaryFunctions,
                    currentGame
                );
                this.setState({
                    loading: false,
                    currentGame: currentGame,
                    predictionsAnsweredModal: false,
                    currentUserAnswersState: organizeUserAnswers
                });
            } catch (error) {
                console.error('Error updating game state in Firebase:', error);
                swal({
                    title: "Oh No!",
                    text: "Check your internet connection and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        });
    }

    removeFromToAddArray(index, name){
        let removeArray = this.state[name];
        removeArray.splice(index, 1)
        this.setState({
            [name]: removeArray
        })
    }

    addRewardToLocalArray(){
        const rewardAmount = this.state.rewardAmount;
        const rewardId = this.state.rewardToAdd;
        const codes = this.state.codes;
        let codesArray = this.state.codesArray;
        let pinsArray = this.state.pinsArray;
        const qrCodes = this.state.qrCodes;
        codesArray = detectHowManyCodesEntered(codesArray);
        pinsArray = detectHowManyCodesEntered(pinsArray);
        if(codesArray.length === 0 && codes){
            swal({
                title: "Hold On!",
                text: 'Must enter codes or uncheck codes box!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if (codes){
            if(codesArray.length !== parseInt(rewardAmount,10)){
                swal({
                    title: 'Codes not equal!',
                    text: "# of codes must equal number of prizes created. In this case: " +  codesArray.length + " does not equal " + rewardAmount,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            } else if(pinsArray.length !== 0 && pinsArray.length !== parseInt(rewardAmount,10)){
                swal({
                    title: 'Pins not equal!',
                    text: "# of pins must equal number of prizes created. In this case: " +  pinsArray.length + " does not equal " + rewardAmount,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }

        if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
            swal({
                title: 'Missing field!',
                text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        for(var ticket in this.state.ticketList){
            if((this.state.ticketList[ticket].key || this.state.ticketList[ticket].id) === rewardId){
                var reward = this.state.ticketList[ticket]
                var rewardToAddObject = {};
                rewardToAddObject.reward = reward;
                rewardToAddObject.amount = rewardAmount;
                rewardToAddObject.codes = codes;
                if(codes){
                    rewardToAddObject.codesArray = codesArray;
                    rewardToAddObject.pinsArray = pinsArray;
                    rewardToAddObject.qrCodes = qrCodes;
                } else {
                    rewardToAddObject.codesArray = [];
                    rewardToAddObject.pinsArray = [];
                    rewardToAddObject.qrCodes = false;
                }
            }
        }

        let typeOfArray = "rewardsAdded"
        let array = this.state.rewardsAdded;
        if(!this.state.showWinning){
            array = this.state.losingRewardsAdded;
            typeOfArray = "losingRewardsAdded"
        }

        const rewardsAddedIds = []
        for(let prize in array){
            rewardsAddedIds.push(array[prize].reward.key || array[prize].reward.id)
        }
        if(rewardsAddedIds.indexOf(rewardId) === -1){
            array = array.concat(rewardToAddObject)
            this.setState({
                [typeOfArray]: array,
                qrCodes: false,
                codes: false
            })
        } else {
            swal({
                title: 'Prize already added!',
                text: "You have already added this prize",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    getSnapshotFromEndpoint(endpoint, numberOfResults=null, searchData=null, orderBy=null) {
        let baseRef = appDatabasePrimaryFunctions.ref(endpoint);
        if(orderBy){
            baseRef = baseRef.orderByChild(orderBy)
        }
        if(numberOfResults){
            baseRef = baseRef.limitToFirst(numberOfResults);
        }
        return baseRef.once('value').then(function(snapshot){
            return snapshot;
        })
    }

    async onNextPage() {
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        const responseFromOrganize = await sortPlayersForAdmin(newAmountToShow, null, appDatabasePrimaryFunctions, this.state.currentGame);
        this.setState({
            showStats:true,
            loading: false,
            itemsToShow: newAmountToShow,
            currentUserAnswersState: responseFromOrganize
        })
    }

    async searchFirebaseData(searchData){
        this.setState({
            loading: true
        })
        let numberOfResults = this.state.itemsPerPage;
        if(searchData){
            numberOfResults = null;
        }
        const organizeUserAnswers = await sortPlayersForAdmin(numberOfResults, searchData, appDatabasePrimaryFunctions, this.state.currentGame);
        this.setState({
            loading: false,
            currentUserAnswersState: organizeUserAnswers
        })
    }

    async getFutureGames(numberOfResults = 10){
        return await base.fetch('futureGamesList', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'activateGame/performAt'
            },
            then(data){
                return data
            }
        })
    }

    render() {
      let selectedGame = this.state.currentGame || null;
      const futureGamesList = this.state.futureGamesList || [];
      let questionList = this.state.questionsList || [];
      let userAnswersCount = this.state.userAnswerCount || 0;
      let currentUserAnswersState = this.state.currentUserAnswersState || [];
      let hideEndGameButton = false;
      let votingStarted = false;
      let questionAnswered = false;
      let question = {};
      let startTimeFirstPart = null;
      let startTimeSecondPart = null;
      let endTimeSecondPart = null;
      let endTimeFirstPart = null;
      const rewardsList = [];
      const losingRewardsList = [];
      let rewardsAdded = this.state.rewardsAdded || [];
      if(!this.state.showWinning){
          rewardsAdded = this.state.losingRewardsAdded || [];
      }
      let gameScheduledStatus = "completed";
      let questionType;
      if(selectedGame && selectedGame.question){
          for(const i in selectedGame.rewards){
              const reward = selectedGame.rewards[i];
              if(typeof reward === "object"){
                  rewardsList.push(reward);
              }
          }
          for(const i in selectedGame.losingRewards){
              const reward = selectedGame.losingRewards[i];
              if(typeof reward === "object"){
                  losingRewardsList.push(reward);
              }
          }
        if(selectedGame.activateGame){
            let performAtTime = returnTimeStampInMilliseconds(selectedGame.activateGame.performAt);
            let endAtTime = returnTimeStampInMilliseconds(selectedGame.activateGame.endAt);
            startTimeFirstPart = new Date(performAtTime).toLocaleDateString();
            startTimeSecondPart = new Date(performAtTime).toLocaleTimeString();
            endTimeFirstPart = new Date(endAtTime).toLocaleDateString();
            endTimeSecondPart = new Date(endAtTime).toLocaleTimeString();
            gameScheduledStatus = selectedGame.activateGame.status
        }
        question = selectedGame.question;
        questionAnswered = selectedGame.answered;
        votingStarted = selectedGame.active;
        questionType = question.questionType;
        if(questionType === "slider"){
          questionType = "Slider";
        } else if(questionType === "write_in"){
          questionType = "Write In"
        } else if(questionType === "time"){
          questionType = "Time"
        } else if(questionType === "score"){
          questionType = "Game Score"
        }
        if((selectedGame.id && selectedGame.answered) || votingStarted || (gameScheduledStatus === "active" && !selectedGame.firstStart) || (gameScheduledStatus === "scheduled" && !selectedGame.firstStart)){
          hideEndGameButton = true;
        }
      } else {
        selectedGame = null
        hideEndGameButton = true
      }
      const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2" style={{display: questionAnswered ? '' : 'none'}}>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px'}}>
                    <p style={{lineHeight:'50px'}}>Game Ended</p>
                  </div>
                </div>
                <div className="col-md-3" style={{display: questionAnswered ? 'none' : ''}}>
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && votingStarted ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Predictions Are Live</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && !votingStarted ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Predictions Are Not Live</p>
                  </div>
                </div>
                <div className="col-md-3">
                    <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: selectedGame && !questionAnswered && votingStarted ? '' : 'none', float:'left', height:'52px' }}>End Predictions</button>
                    <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && !votingStarted && !questionAnswered ? '' : 'none', float:'left', height:'52px' }}>Start Predictions</button>
                </div>
                <div className="col-md-2">
                  <button onClick={() => this.endGame()} className="btn btn-primary btn-lg" style={{display: hideEndGameButton ? 'none' : '', float:'left', height:'52px' }}>Answer</button>
                </div>
                <div className="col-md-4" style={{textAlign:'right'}}>
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame && selectedGame.id ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
                <div className="container">
                    <div className="row">
                        {startTimeFirstPart &&
                        <div className="col-md-4"
                             style={{color: "black", border: "solid 1px #d3d3d3", margin: 10, borderRadius: 5}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <u style={{color: "#353839"}}>Starts</u>
                                    <br/>
                                    <span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>
                                </div>
                                <div className="col-md-6">
                                    <u style={{color: "#353839"}}>Ends</u>
                                    <br/>
                                    <span style={{fontSize: 18}}>{endTimeFirstPart}<br/>{endTimeSecondPart}</span>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginTop:10, marginBottom:10, marginLeft:10, borderRadius: 5}}>
                            <div className="row">
                                {/*<div className="col-md-6" style={{textAlign:'center'}}>*/}
                                {/*    <span style={{fontSize:12}}>Current Game Players</span>*/}
                                {/*    <footer className="value-text">{players}</footer>*/}
                                {/*</div>*/}
                                <div className="col-md-12" style={{textAlign:'center'}}>
                                    <span style={{fontSize:12}}>Answers</span>
                                    <footer className="value-text">{userAnswersCount}</footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="card-body" style={{padding:0}}>
                  <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                      <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                          <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                              <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                              <span className="d-none d-lg-block">Game Details</span>
                          </a>
                      </li>
                      <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                          <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                              <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                              <span className="d-none d-lg-block">Results</span>
                          </a>
                      </li>
                      <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                          <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                              <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                              <span className="d-none d-lg-block">Future Games</span>
                          </a>
                      </li>
                  </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                    <div className="row" style={{marginLeft: 10, marginRight: 10}}>
                        <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                            <span className="fa fa-arrow-circle-down"/> Download Participants
                        </div>
                        <div className="form-group" style={{marginLeft: 'auto', marginBottom: 'auto', marginTop: 'auto'}}>
                            <input id="searchQuery" name="searchQuery" className="form-control" placeholder="search" type="text" onChange={()=>{
                                const vm = this;
                                clearTimeout(this.timeoutId); // no-op if invalid id
                                this.timeoutId = setTimeout(function(){
                                    let input = document.getElementById('searchQuery').value;
                                    vm.searchFirebaseData(input);
                                }, 500);
                            }}/>
                        </div>
                    </div>
                    <div style={{height:'10px', width:'100%'}}/>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <th>Answer</th>
                        <th>Time Stamp</th>
                        <th>Reward Sent</th>
                        <th>Code</th>
                      </tr>
                      {
                        currentUserAnswersState.map(function(item,i){
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.answer}</td>
                              <td style={{fontFamily:'Open Sans' }}>{convertTimeStampToHumanReadable(parseInt(item.timeStamp))}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                            </tr>
                        }, this)
                      }
                      {currentUserAnswersState && userAnswersCount > currentUserAnswersState.length && !document.getElementById('searchQuery').value  &&
                          <button className="btn btn-primary" onClick={()=>this.onNextPage()}>
                              More
                          </button>
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Rewards</h4>
                                {rewardsList.length > 0 ?
                                    rewardsList.map(function(item, index){
                                        return (
                                            <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                                <div className="col-md-6">
                                                    {item.rewardName}
                                                </div>
                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                    Amount: {item.amount}
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{textAlign:'left', color:'black'}}>
                                        <p>No Rewards Added</p>
                                    </div>
                                }
                                {losingRewardsList && losingRewardsList.length > 0 &&
                                    <>
                                        <h4 style={{color:'black', marginTop: 10}}>Losing Rewards</h4>
                                        {
                                            losingRewardsList.map(function(item, index){
                                                return (
                                                    <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                                        <div className="col-md-6">
                                                            {item.rewardName}
                                                        </div>
                                                        <div className="col-md-6" style={{textAlign:"right"}}>
                                                            Amount: {item.amount}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </div>
                            <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Prediction</h4>
                                <div style={{backgroundColor: "#FAFBFE", padding:10}}>
                                    <p style={{color:'black'}}>{question.questionText}</p>
                                    <p style={{color:'black'}}>Type of prediction: {questionType}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  <div style={{display: this.state.showGames ? 'block' : 'none'}}>
                      <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                      <table className="table table-striped" style={{color:'black'}}>
                          <tbody>
                          <tr>
                              <th>Name</th>
                              <th>Date</th>
                              <th>Make Current Game</th>
                              <th>Delete Game</th>
                          </tr>
                          {
                              futureGamesList.map(function(item,i){
                                  var winningRacer = null;
                                  for(var winnerIndex in item.racers){
                                      var racer = item.racers[winnerIndex];
                                      if(racer.winner){
                                          winningRacer = racer.racerName;
                                      }
                                  }
                                  var dateTime = convertTimeStampToHumanReadable(item.activateGame.performAt)
                                  return <tr key={i}>
                                      <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                                      <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                                      <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.makeFutureGameCurrent(item)}>MAKE CURRENT</button></td>
                                      <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                                  </tr>
                              }, this)
                          }
                          </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                        <style dangerouslySetInnerHTML={{
                                            __html: [
                                                '.react-datepicker-time__input {',
                                                    'width: 100% !important;',
                                                '}'
                                            ].join('\n')
                                        }}/>
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="name3"> Select Prediction</label>
                                        <div className="col-md-9">
                                          <select className="form-control" name="question_select" id="question_select" value={this.state.question_select} onChange={this.handleChange}>
                                            <option/>
                                            {
                                              questionList.map(function(item,index){
                                                return(
                                                  <option value={item.key} key={index}>{item.questionText}</option>
                                                )
                                              })
                                            }
                                          </select>
                                        </div>
                                      </div>
                                        <div className="form-check">
                                            <input value={this.state.schedule_game} className="form-check-input" id="schedule_game" name="schedule_game" type="checkbox" checked={this.state.schedule_game} onChange={this.handleChange} />
                                            <label className="form-check-label" htmlFor="schedule_game">Schedule Game</label>
                                        </div>
                                        <div className="form-group row mb-3 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                            <label className="col-md-3 col-form-label" htmlFor="schedule_game_time"> Game Start Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_game_time} onChange={date => this.setState({schedule_game_time: date})}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                            <label className="col-md-3 col-form-label" htmlFor="schedule_end_game_time"> Game End Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_end_game_time} onChange={date => this.setState({schedule_end_game_time: date})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                      <div className="tab-pane" id="second">
                          <div className="form-check">
                              <input onChange={this.handleChange} name="noPrizes" value={this.state.noPrizes} checked={this.state.noPrizes} type="checkbox" className="form-check-input" id="noPrizes"/>
                              <label className="form-check-label" htmlFor="exampleCheck1">No Prizes</label>
                          </div>
                          {!this.state.noPrizes &&
                              <div className="form-horizontal">
                                  <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                      <li className="nav-item" onClick={()=> this.switchWinning(true)}>
                                          <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showWinning">
                                              <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                              <span className="d-none d-lg-block">Winning</span>
                                          </a>
                                      </li>
                                      <li className="nav-item" onClick={()=> this.switchWinning(false)}>
                                          <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showLosing">
                                              <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                              <span className="d-none d-lg-block">Losing</span>
                                          </a>
                                      </li>
                                  </ul>
                                  <div className="row">
                                      <div className="col-12">
                                          <div className="form-group row mb-3">
                                              <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                              <div className="col-md-9">
                                                  <select className="form-control" onChange={this.saveReward}>
                                                      <option/>
                                                      {
                                                          this.state.ticketList.map(function(item,index){
                                                              return(
                                                                  <option value={item.key || item.id} key={index}>{item.rewardName}</option>
                                                              )
                                                          })
                                                      }
                                                  </select>
                                              </div>
                                          </div>
                                          <div className="form-group row mb-3">
                                              <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                              <div className="col-md-9">
                                                  <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                              </div>
                                          </div>

                                          <div className="form-check">
                                              <input onChange={this.handleChange} name="codes" value={this.state.codes} checked={this.state.codes} type="checkbox" className="form-check-input" id="codes"/>
                                              <label className="form-check-label" htmlFor="codes">Redeem Codes?</label>
                                          </div>

                                          {this.state.codes && !this.state.tenantVariables.doNotCollectEmail &&
                                              <div className="form-check">
                                                  <input onChange={this.handleChange} name="qrCodes" value={this.state.qrCodes} checked={this.state.qrCodes} type="checkbox" className="form-check-input" id="qrCodes"/>
                                                  <label className="form-check-label" htmlFor="qrCodes">QR Codes?</label>
                                              </div>
                                          }

                                          <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                              <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                              <div className="col-md-9">
                                                  <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                              </div>
                                          </div>

                                          <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                              <label htmlFor="pinsArray" className="col-md-3 col-form-label">Enter Pins</label>
                                              <div className="col-md-9">
                                                  <textarea value={this.state.pinsArray} className="form-control" name="pinsArray" onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                              </div>
                                          </div>

                                          <div className="form-group row mb-3" align="center">
                                              <div className="col-md-12">
                                                  <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                              </div>
                                          </div>

                                          {rewardsAdded && rewardsAdded.length > 1 &&
                                              <div className="form-check" >
                                                  <input value={this.state.showWinning ? this.state.randomOrder : this.state.losingRandomOrder} className="form-check-input" id={this.state.showWinning ? "randomOrder" : "losingRandomOrder"} name={this.state.showWinning ? "randomOrder" : "losingRandomOrder"} type="checkbox" checked={this.state.showWinning ? this.state.randomOrder : this.state.losingRandomOrder} onChange={this.handleChange} />
                                                  <label className="form-check-label" htmlFor={this.state.showWinning ? "randomOrder" : "losingRandomOrder"}>Give Out In Random Order</label>
                                              </div>
                                          }

                                          <div className="form-group mb-3">
                                              <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                                  {
                                                      rewardsAdded.map(function(item,index){
                                                          let codesText = "No"
                                                          if(item.codes){
                                                              codesText = "Yes"
                                                          }
                                                          return(
                                                              <div key={index} className="form-group">
                                                                  <li>) {item.amount} - {item.reward.rewardName}; Codes: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.state.showWinning ? vm.removeFromToAddArray(index, "rewardsAdded") : vm.removeFromToAddArray(index, "losingRewardsAdded")}/></li>
                                                              </div>
                                                          )
                                                      })
                                                  }
                                              </ol>
                                          </div>
                                          <div style={{height: 20,width:1}}/>
                                      </div>
                                  </div>
                              </div>
                          }
                      </div>

                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>

                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a>
                            </li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
          <Modal isOpen={this.state.predictionsAnsweredModal} toggle={this.togglePredictionsAnsweredModal} style={{width: '90%'}} id="myModal2">
              <div className="card">
                  <div className="card-body">
                      <h4 className="header-title mb-3"> Predictions To Answer</h4>
                      <p>{question.questionText}</p>
                      {question.questionType === "time" &&
                          <div className="form-group mb-3">
                              <TimePicker
                                  onChange={this.handleTimeChange}
                                  value={this.state.correctAnswer}
                                  name="correctAnswer"
                                  minutePlaceholder="00"
                                  secondPlaceholder="00"
                                  defaultValue="00:00"
                                  disableClock={true}
                                  maxDetail="second"
                                  format="mm:ss"
                                  clearIcon={null}
                                  className="timeInput"
                              />
                          </div>
                      }
                      {(question.questionType === "write_in" || question.questionType === "slider") &&
                          <div className="form-group mb-3">
                              <input name="correctAnswer" className="form-control" type="number" value={this.state.correctAnswer} onChange={this.handleChange}/>
                          </div>
                      }
                      {question.questionType === "score" &&
                      <div className="form-group mb-3">
                          <label htmlFor="teamOneScore">Team One Score</label>
                          <input name="teamOneScore" className="form-control" type="number" value={this.state.teamOneScore} onChange={this.handleChange}/>
                          <label htmlFor="teamTwoScore">Team Two Score</label>
                          <input name="teamTwoScore" className="form-control" type="number" value={this.state.teamTwoScore} onChange={this.handleChange}/>
                      </div>
                      }
                      <button className="btn btn-success" onClick={() => vm.markAsCorrect()}>Answer Prediction and Send Emails</button>
                  </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
